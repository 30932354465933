// 导入自定义 Layout 布局
import { BaseLayout, RouterView } from '@/layouts'

// 基础路由
export const routes = [
  {
    path: '/',
    redirect: '/copyright'
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/user/Login')
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('@/views/Home')
  },
  {
    path: '/summary-data',
    name: '汇总数据',
    component: () => import('@/views/user/SummaryData')
  }
]

// 管理中心
export const copyrightRoutes = [
  {
    path: '/copyright',
    redirect: '/copyright-home',
    component: BaseLayout,
    meta: { title: '管理中心', keepAlive: false },
    children: [
      {
        path: '/copyright-home',
        name: 'CopyrightHome',
        component: () => import('@/views/copyright/home/Home'),
        meta: { title: '首页', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 }
      },
      {
        path: '/copyright-content',
        redirect: '/copyright-film-library',
        component: RouterView,
        meta: { title: '内容管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-film-library',
            name: 'CopyrightFilmLibrary',
            component: () => import('@/views/copyright/content/FilmLibrary'),
            meta: { title: '片库', keepAlive: false, icon: 'PushpinOutlined' }
          },
          // {
          //   path: '/copyright-channels',
          //   name: 'CopyrightChannels',
          //   component: () => import('@/views/copyright/content/Channels'),
          //   meta: { title: '分发渠道管理', keepAlive: false, icon: 'PushpinOutlined' }
          // },,
          {
            path: '/copyright-drama-change-logs',
            name: 'CopyrightDramaChangeLogs',
            component: () => import('@/views/copyright/content/DramaChangeLogs'),
            meta: { title: '剧集状态变更日志', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-plot-types',
            name: 'CopyrightPlotTypes',
            component: () => import('@/views/copyright/content/PlotTypes'),
            meta: { title: '剧情类型', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-plot-tags',
            name: 'CopyrightPlotTags',
            component: () => import('@/views/copyright/content/PlotTags'),
            meta: { title: '剧情标签', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-rec-channels',
            name: 'CopyrightRecChannels',
            component: () => import('@/views/copyright/content/RecChannels'),
            meta: { title: '推荐频道', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-aggregation-page',
            name: 'CopyrightAggregationPage',
            component: () => import('@/views/copyright/content/AggregationPage'),
            meta: { title: '内容聚合页', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-ranking',
            name: 'CopyrightRanking',
            component: () => import('@/views/copyright/content/Ranking'),
            meta: { title: '榜单页', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-authorize',
            name: 'CopyrightAuthorize',
            component: () => import('@/views/copyright/content/Authorize'),
            meta: { title: 'UBMax*AIGC授权', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-suppliers',
        redirect: '/copyright-script-suppliers',
        component: RouterView,
        meta: { title: '供应商管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-script-suppliers',
            name: 'CopyrightScriptSuppliers',
            component: () => import('@/views/copyright/suppliers/ScriptSuppliers'),
            meta: { title: '剧本供应商', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-shot-suppliers',
            name: 'CopyrightShotSuppliers',
            component: () => import('@/views/copyright/suppliers/ShotSuppliers'),
            meta: { title: '摄制供应商', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-film-suppliers',
            name: 'CopyrightFilmSuppliers',
            component: () => import('@/views/copyright/suppliers/FilmSuppliers'),
            meta: { title: '成片供应商', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-operate',
        redirect: '/copyright-refund',
        component: RouterView,
        meta: { title: '运营管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-refund',
            name: 'CopyrightRefund',
            component: () => import('@/views/copyright/operate/Refund'),
            meta: { title: '用户退款', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-kssettlement',
            name: 'CopyrightKsSettlement',
            component: () => import('@/views/copyright/operate/KsSettlement'),
            meta: { title: '快手结算', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-wecom',
            name: 'CopyrightWecom',
            component: () => import('@/views/copyright/operate/Wecom'),
            meta: { title: '企微列表', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-wxplayer-config',
            name: 'CopyrightWxPlayerConfig',
            component: () => import('@/views/copyright/operate/WxPlayerConfig'),
            meta: { title: '微小播放器配置', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-play-records',
            name: 'CopyrightPlayRecords',
            component: () => import('@/views/copyright/operate/PlayRecords'),
            meta: { title: '播放记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-activities',
            name: 'CopyrightActivities',
            component: () => import('@/views/copyright/operate/Activities'),
            meta: { title: '活动管理', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-charge-page',
            name: 'CopyrightChargePage',
            component: () => import('@/views/copyright/operate/ChargePage'),
            meta: { title: '独立充值页', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-complaints',
            name: 'CopyrightComplaints',
            component: () => import('@/views/copyright/operate/Complaints'),
            meta: { title: '用户投诉', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-gift-management',
            name: 'CopyrightGiftManagement',
            component: () => import('@/views/copyright/operate/GiftManagement'),
            meta: { title: '礼品管理', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-exchange-gift-record',
            name: 'CopyrightGiftExchangeRecord',
            component: () => import('@/views/copyright/operate/GiftExchangeRecord'),
            meta: { title: '兑换礼品记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-ttplayer-settings',
            name: 'CopyrightTtPlayerSettings',
            component: () => import('@/views/copyright/operate/TtPlayerSettings'),
            meta: { title: '抖小播放器配置', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-expert-operate',
        redirect: '/copyright-ttintermediarys',
        component: RouterView,
        meta: { title: '达人运营', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-video-data',
            name: 'CopyrightVideoData',
            component: () => import('@/views/copyright/operate/VideoData'),
            meta: { title: '视频号挂载数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-video-number-expertdb',
            name: 'CopyrightVideoNumberExpertDb',
            component: () => import('@/views/copyright/expert-operate/VideoNumberExpertDb'),
            meta: { title: '视频号达人库', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-ttintermediarys',
            name: 'CopyrightTtIntermediarys',
            component: () => import('@/views/copyright/expert-operate/TtIntermediarys'),
            meta: { title: '抖小达人中介', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-xingtu',
            name: 'CopyrightXingTu',
            component: () => import('@/views/copyright/expert-operate/XingTu'),
            meta: { title: '星图融合任务', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-expert-settlement',
            name: 'CopyrightExpertSettlement',
            component: () => import('@/views/copyright/expert-operate/Settlement'),
            meta: { title: '达人团长结算', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-resellerold',
        redirect: '/copyright-resellerold-day-data',
        component: RouterView,
        meta: { title: '分销管理(旧)', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-resellerold-day-data',
            name: 'CopyrightReselleroldDayData',
            component: () => import('@/views/copyright/resellerold/ResellerDayData'),
            meta: { title: '每日分销数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-resellerold-data',
            name: 'CopyrightReselleroldData',
            component: () => import('@/views/copyright/resellerold/ResellerData'),
            meta: { title: '分销商数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-resellerold-manager',
            name: 'CopyrightReselleroldManager',
            component: () => import('@/views/copyright/resellerold/ResellerManager'),
            meta: { title: '分销商管理', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-resellerold-settlement',
            name: 'CopyrightReselleroldSettlement',
            component: () => import('@/views/copyright/resellerold/ResellerSettlement'),
            meta: { title: '分销结算单', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-reseller',
        redirect: '/copyright-reseller-link',
        component: RouterView,
        meta: { title: '分销管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-reseller-iap-link',
            name: 'CopyrightResellerIapLink',
            component: () => import('@/views/copyright/reseller/IapLink'),
            meta: { title: 'IAP推广链接汇总', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-reseller-iaa-link',
            name: 'CopyrightResellerIaaLink',
            component: () => import('@/views/copyright/reseller/IaaLink'),
            meta: { title: 'IAA推广链接汇总', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-reseller-data',
            name: 'CopyrightResellerData',
            component: () => import('@/views/copyright/reseller/ResellerData'),
            meta: { title: '分销商数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-reseller-drama-data',
            name: 'CopyrightResellerDramaData',
            component: () => import('@/views/copyright/reseller/ResellerDramaData'),
            meta: { title: '短剧分销数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-reseller-manager',
            name: 'CopyrightResellerManager',
            component: () => import('@/views/copyright/reseller/ResellerManager'),
            meta: { title: '分销商管理', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-reseller-settlement',
            name: 'CopyrightResellerSettlement',
            component: () => import('@/views/copyright/reseller/ResellerSettlement'),
            meta: { title: '分销结算单', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-project',
        redirect: '/copyright-projects',
        component: RouterView,
        meta: { title: '项目配置', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-projects',
            name: 'CopyrightProjects',
            component: () => import('@/views/copyright/project/Projects'),
            meta: { title: '项目管理', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-projects-config',
            name: 'CopyrightProjectsConfig',
            component: () => import('@/views/copyright/project/ProjectsConfig'),
            meta: { title: '配置管理', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-permission',
        redirect: '/copyright-accounts',
        component: RouterView,
        meta: { title: '账号与权限', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-accounts',
            name: 'CopyrightAccounts',
            component: () => import('@/views/copyright/permission/Accounts'),
            meta: { title: '账号管理', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/copyright-settings',
        redirect: '/copyright-accounts',
        component: RouterView,
        meta: { title: '系统设置', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/copyright-gl-permission',
            name: 'GlPermission',
            component: () => import('@/views/copyright/settings/GlPermission'),
            meta: { title: '管理中心权限', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/copyright-yy-permission',
            name: 'YyPermission',
            component: () => import('@/views/copyright/settings/YyPermission'),
            meta: { title: '运营中心权限', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      }
    ]
  }
]

// 运营中心
export const operateRoutes = [
  {
    path: '/operate',
    redirect: '/operate-home',
    component: BaseLayout,
    meta: { title: '运营中心', keepAlive: false, hasDropdown: true, dropdownOption: 'project' },
    children: [
      {
        path: '/operate-home',
        name: 'OperateHome',
        component: () => import('@/views/operate/home/Home'),
        meta: { title: '首页', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 }
      },
      {
        path: '/operate-content',
        redirect: '/operate-film-library',
        component: RouterView,
        meta: { title: '内容管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-film-library',
            name: 'OperateFilmLibrary',
            component: () => import('@/views/operate/content/FilmLibrary'),
            meta: { title: '片库', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-wait-library',
            name: 'OperateWaitLibrary',
            component: () => import('@/views/operate/content/WaitLibrary'),
            meta: { title: '待入库', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/operate-promotion',
        redirect: '/operate-film-library',
        component: RouterView,
        meta: { title: '推广管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-promotion-link',
            name: 'OperatePromotionLink',
            component: () => import('@/views/operate/promotion/Link'),
            meta: { title: '推广链接', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-promotion-data',
            name: 'OperatePromotionData',
            component: () => import('@/views/operate/promotion/Data'),
            meta: { title: '推广数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-promotion-agent',
            name: 'OperatePromotionAgent',
            component: () => import('@/views/operate/promotion/Agent'),
            meta: { title: '推广代理商', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-returned',
            name: 'OperateReturned',
            component: () => import('@/views/operate/promotion/Returned'),
            meta: { title: '已回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-noreturned',
            name: 'OperateNoReturned',
            component: () => import('@/views/operate/promotion/NoReturned'),
            meta: { title: '未回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-virtual-Returned',
            name: 'OperateVirtualReturned',
            component: () => import('@/views/operate/promotion/VirtualReturned'),
            meta: { title: '虚拟回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/operate-settlement',
        redirect: '/operate-settlement-list',
        component: RouterView,
        meta: { title: '结算中心', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-settlement-list',
            name: 'OperateSettlementList',
            component: () => import('@/views/operate/settlement/SettlementList'),
            meta: { title: '我的结算单', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-sharing-list',
            name: 'OperateSharingList',
            component: () => import('@/views/operate/settlement/SharingList'),
            meta: { title: '每日分成数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
        ]
      },
      {
        path: '/operate-wecom',
        redirect: '/operate-friend-msg',
        component: RouterView,
        meta: { title: '运营管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-subscription-push',
            name: 'OperateSubscriptionPush',
            component: () => import('@/views/operate/wecom/SubscriptionPush'),
            meta: { title: '微小订阅消息推送', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-subscription-push-tt',
            name: 'OperateSubscriptionPushTt',
            component: () => import('@/views/operate/wecom/SubscriptionPushTt'),
            meta: { title: '抖小订阅消息推送', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-friend-msg',
            name: 'OperateFriendMsg',
            component: () => import('@/views/operate/wecom/FriendMsg'),
            meta: { title: '企微好友消息推送', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-keyword-reply',
            name: 'OperateKeywordReply',
            component: () => import('@/views/operate/wecom/KeywordReply'),
            meta: { title: '关键词回复', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-service-list',
            name: 'OperateServiceList',
            component: () => import('@/views/operate/wecom/ServiceList'),
            meta: { title: '企业客服列表', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/operate-user',
        redirect: '/operate-register',
        component: RouterView,
        meta: { title: '用户管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-register',
            name: 'OperateRegister',
            component: () => import('@/views/operate/user/Register'),
            meta: { title: '注册用户', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-purchase-record',
            name: 'OperatePurchaseRecord',
            component: () => import('@/views/operate/user/PurchaseRecord'),
            meta: { title: '购买记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-refund-record',
            name: 'OperateRefundRecord',
            component: () => import('@/views/operate/user/RefundRecord'),
            meta: { title: '退款记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-consume-record',
            name: 'OperateConsumeRecord',
            component: () => import('@/views/operate/user/ConsumeRecord'),
            meta: { title: '消费记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-recharge',
            name: 'OperateRecharge',
            component: () => import('@/views/operate/user/Recharge'),
            meta: { title: '手动充值', keepAlive: false, icon: 'PushpinOutlined' }
          },
          // {
          //   path: '/operate-coupon-record',
          //   name: 'OperateCouponRecord',
          //   component: () => import('@/views/operate/user/CouponRecord'),
          //   meta: { title: '抵扣券记录', keepAlive: false, icon: 'PushpinOutlined' }
          // }
        ]
      },
      {
        path: '/operate-config',
        redirect: '/operate-register',
        component: RouterView,
        meta: { title: '前台配置', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-nav',
            name: 'OperateNav',
            component: () => import('@/views/operate/config/Nav'),
            meta: { title: '首页顶部二级导航', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-banners',
            name: 'OperateBanners',
            component: () => import('@/views/operate/config/Banners'),
            meta: { title: '首页首屏Banner', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-icon-nav',
            name: 'OperateIconNav',
            component: () => import('@/views/operate/config/IconNav'),
            meta: { title: '首页图标导航', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-rankings',
            name: 'OperateRankings',
            component: () => import('@/views/operate/config/Rankings'),
            meta: { title: '首页榜单', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-classifys',
            name: 'OperateClassifys',
            component: () => import('@/views/operate/config/Classifys'),
            meta: { title: '首页分类板块', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-charge-config',
            name: 'OperateChargeConfig',
            component: () => import('@/views/operate/config/ChargeConfig'),
            meta: { title: '收费设置', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-coin-custom',
            name: 'OperateCoinCustom',
            component: () => import('@/views/operate/config/CoinCustom'),
            meta: { title: '自定义充值模板', keepAlive: false, icon: 'PushpinOutlined' }
          },
          // {
          //   path: '/operate-coupon-management',
          //   name: 'OperateCouponManagement',
          //   component: () => import('@/views/operate/config/CouponManagement'),
          //   meta: { title: '抵扣券管理', keepAlive: false, icon: 'PushpinOutlined' }
          // },
          {
            path: '/operate-guide',
            name: 'OperateGuide',
            component: () => import('@/views/operate/config/Guide'),
            meta: { title: '引导配置', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-welfare-banners',
            name: 'OperateWelfareBanners',
            component: () => import('@/views/operate/config/WelfareBanners'),
            meta: { title: '福利-首屏banner', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-welfare-tasks',
            name: 'OperateWelfareTasks',
            component: () => import('@/views/operate/config/WelfareTasks'),
            meta: { title: '福利-日常任务', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-welfare-ext-tasks',
            name: 'OperateWelfareExtTasks',
            component: () => import('@/views/operate/config/WelfareExtTasks'),
            meta: { title: '福利-站外任务', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-sodrama-list',
            name: 'OperateSoDramaList',
            component: () => import('@/views/operate/config/SoDramaList'),
            meta: { title: '特价剧', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/operate-user-banners',
            name: 'OperateUserBanners',
            component: () => import('@/views/operate/config/UserBanners'),
            meta: { title: '用户中心banner', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/operate-settings',
        redirect: '/operate-accounts',
        component: RouterView,
        meta: { title: '系统设置', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/operate-accounts',
            name: 'OperateAccounts',
            component: () => import('@/views/operate/settings/Accounts'),
            meta: { title: '成员管理', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      }
    ]
  }
]

// 推广代理
export const promotionRoutes = [
  {
    path: '/promotion',
    redirect: '/promotion-data',
    component: BaseLayout,
    meta: { title: '推广代理商后台', keepAlive: false },
    children: [
      {
        path: '/promotion-data',
        redirect: '/promotion-data-link',
        component: RouterView,
        meta: { title: '推广管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/promotion-data-link',
            name: 'PromotionDataLink',
            component: () => import('@/views/promotion/data/Link'),
            meta: { title: '推广链接', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-coin-custom',
            name: 'PromotionCoinCustom',
            component: () => import('@/views/promotion/data/CoinCustom'),
            meta: { title: '自定义充值模板', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-data-summary',
            name: 'PromotionDataSummary',
            component: () => import('@/views/promotion/data/Summary'),
            meta: { title: '数据汇总', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-returned',
            name: 'PromotionReturned',
            component: () => import('@/views/promotion/data/Returned'),
            meta: { title: '已回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-noreturned',
            name: 'PromotionNoReturned',
            component: () => import('@/views/promotion/data/NoReturned'),
            meta: { title: '未回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-virtual-Returned',
            name: 'PromotionVirtualReturned',
            component: () => import('@/views/promotion/data/VirtualReturned'),
            meta: { title: '虚拟回传数据', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/promotion-content',
        redirect: '/promotion-film-library',
        component: RouterView,
        meta: { title: '内容管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/promotion-film-library',
            name: 'PromotionFilmLibrary',
            component: () => import('@/views/promotion/content/FilmLibrary'),
            meta: { title: '片库', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      },
      {
        path: '/promotion-settlement',
        redirect: '/promotion-settlement-list',
        component: RouterView,
        meta: { title: '结算中心', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/promotion-settlement-list',
            name: 'PromotionSettlementList',
            component: () => import('@/views/promotion/settlement/SettlementList'),
            meta: { title: '我的结算单', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-sharing-list',
            name: 'PromotionSharingList',
            component: () => import('@/views/promotion/settlement/SharingList'),
            meta: { title: '每日分成数据', keepAlive: false, icon: 'PushpinOutlined' }
          },
        ]
      },
      {
        path: '/promotion-user',
        redirect: '/promotion-register',
        component: RouterView,
        meta: { title: '用户管理', keepAlive: false, icon: 'menu_0_nomal.png', type: 2 },
        children: [
          {
            path: '/promotion-register',
            name: 'PromotionRegister',
            component: () => import('@/views/promotion/user/Register'),
            meta: { title: '注册用户', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-purchase-record',
            name: 'PromotionPurchaseRecord',
            component: () => import('@/views/promotion/user/PurchaseRecord'),
            meta: { title: '购买记录', keepAlive: false, icon: 'PushpinOutlined' }
          },
          {
            path: '/promotion-refund-record',
            name: 'PromotionRefundRecord',
            component: () => import('@/views/promotion/user/RefundRecord'),
            meta: { title: '退款记录', keepAlive: false, icon: 'PushpinOutlined' }
          }
        ]
      }
    ]
  }
]
